* {
  margin: 0;
  padding: 0;
}

.House-details {
  display: grid;
  grid-template-columns: 20% 80%;
  height: auto;
  margin-bottom: 2rem;
  padding-top: 5rem;
}

.side-slider {
  margin-top: 40px;
  margin-left: 38px;
  width: 80%;
  max-height: 900px;
}

.slick-list {
  overflow: hidden !important;
  max-height: 860px;
}

.side-slider img {
  width: 100%;
  height: 100%;
  max-height: 12%;
  object-fit: cover;
  margin-bottom: 0px;
}

.next-arrow-details {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: none;
  color: white;
  left: 0px;
  margin-top: -8px;
  transform: translateY(-50%);
}

.prev-arrow-details {
  position: absolute;
  top: 100%;
  z-index: 1;
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: none;
  color: white;
  left: 0px;
  margin-top: 8px;
  transform: translateY(-50%);
}

.a-div {
  background-color: hsl(274, 70%, 34%, 0.6);
  padding: 5px 10px 2px 10px;
  border-radius: 5px;
}

.next-arrow-details {
  top: 10px;
}

.prev-arrow-details {
  bottom: 0px;
}

.a-div:hover {
  color: white;
  background-color: hsl(274, 70%, 34%, 1);
}

.side-slider .arrow {
  width: 20px;
  height: 20px;
  max-height: 50%;
  object-fit: cover;
  margin-bottom: 0px;
}

.main {
  text-align: center;
  width: 90%;
  margin-left: 3.5%;
  margin-right: 5%;
  padding-left: 10px;
  margin-top: 20px;
}

.main-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.main-img img {
  width: 100%;
  object-fit: contain;
}

.LoadDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  z-index: 1000;
}

.property-info {
  display: grid;
  grid-template-columns: 40% 30% 30%;
  background-color: rgba(150, 150, 150, 0.1);
  padding-bottom: 1rem;
  padding-left: 0rem;
  justify-content: left;
  margin-bottom: 5px;
}

.property-info div {
  display: flex;
  flex-direction: column;
  padding: 10px 0px 0px 5px;
  text-align: left;
}

.label {
  font-size: 14px;
  font-weight: bold;

  color: #808080;
}

.property-header {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 5px;
  margin-top: 10px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1); 
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  background-color: rgba(150, 150, 150, 0.1);
}

.header-details {
  font-size: 14px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
}

.property-description {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 5px;
  padding-right: 10px;
  background-color: #9696961a;
}

.listing-info {
  display: flex;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 5px;
  justify-content: left;
}

.listing-detail {
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  padding-left: 5px;
  padding-top: 10px;
  margin-bottom: 20px;
}

.list-and-contact {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 30px;
}

.grid-one-detail, .grid-two-detail {
  width: 100%;
}

.interior {
  display: flex;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  justify-content: left;
  background-color: rgba(150, 150, 150, 0.1);
  padding-left: 5px;
}

.interior-detail {
  background-color: rgba(150, 150, 150, 0.1);
  padding-left: 5px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-bottom: 1rem;
}

.interior-list {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
}
.interior-list div {
  text-align: left;
  padding: 5px 5px 5px 0px;
}

.interior-name {
  font-weight: bold;
}

.related-house {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.similar-header {
  font-size: large;
  text-align: left;
  padding-bottom: 15px;
}

.side-details {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3rem;
  margin-left: 13px;
}

.view-more-listing-button {
  background-color: white;
  color: black;
  position: relative;
  bottom: 0px;
  border: 1px solid black;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 10px;
}

.view-more-listing-button:hover {
  background-color: #5d1a91;
  color: white;
}

.House-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  background-color: #d9d9d9;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
  transition: transform 0.3s;
}

.house-item-del {
  width: 250px !important;
}



.House-item-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.House-item-info {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto;
  gap: 8px;
  padding: 16px;
}

.House-item-data {
  color: black !important;
  grid-column: 2;
}

.House-item-detail {
  font-size: 0.8em;
  color: grey;
  padding-left: 4px;
}

.House-item-details {
  position: absolute;
  width: 300px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 0;
  font-size: 1.2em;
  opacity: 0;
  transition: opacity 0.3s;
}


.house-view-detail {
  width: 250px !important;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}


.House-item:hover .House-item-details {
  opacity: 1;
}

.House-item-details a {
  color: white;
  text-decoration: none;
  padding-left: 30px;
}

.House-item-city {
  font-size: 0.9em;
  grid-column: 1 / -1;
  margin: 0;
  padding-left: 4px;
}

.House-item-price {
  font-weight: bolder;
  font-family: Cardo, serif;
  font-size: 1.6em;
  color: black;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 4px;
}

.House-item-divider {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 0px;
  margin-bottom: 26px;
}

@media only screen and (max-width: 1000px) {
  .House-details {
    display: block;
    grid-template-columns: 20% 80%;
    height: auto;
    margin-bottom: 2rem;
    padding-top: 5rem;
  }

  .list-and-contact {
    display: block;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 30px;
  }

  .next-arrow-details {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    background-color: transparent;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: none;
    color: white;
    left: -20px;
    margin-top: 14px;
    transform: translateY(-50%);
  }
  
  .prev-arrow-details {
    position: absolute;
    top: 100%;
    z-index: 1;
    cursor: pointer;
    background-color: transparent;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: right;
    padding: none;
    color: white;
    left: 20px;
    margin-top: -26px;
    transform: translateY(-50%);
  }
}
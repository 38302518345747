body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.App {
  position: relative;
  animation: fadeIn 1s ease-in-out;
}

.App-logo {
  width: 50mm;
  margin-left: 30px;
  animation: slideDown 1s ease-in-out;
}

.App-header {
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  background-color: transparent;
  position: fixed;
  z-index: 2;
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  color: white;
}

.App-header.scrolled {
  background-color: hsl(0, 0%, 85%, 0.8);
  transition: background-color 0.7s ease;
  height: 85px;
}

.App-header.scrolled .App-logo {
  width: 40mm;
  transition: width 0.7s ease;
}

.App-header.scrolled .Home-link,
.App-header.scrolled .About-link,
.App-header.scrolled .Contact-link,
.App-header.scrolled .Search-link {
  transition: font-size 0.7s ease;
  font-size: calc(6px + 1.2vmin);
}

.App-footer {
  font-family: "Quicksand", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #5d1a91;
  color: white;
  padding: 20px;
  flex-wrap: wrap;
}

.footer-left,
.footer-right {
  padding: 20px;
}

.footer-left {
  flex: 0 0 15%;
}

.footer-logo {
  width: 150px;
  margin-bottom: 20px;
}

.footer-facebook {
  width: 30px;
  height: 30px;
}

.footer-right {
  flex: 0 0 70%;
}

.footer-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.footer-links a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.footer-links a:hover {
  color: #f0f0f0;
}

.footer-disclaimer {
  font-size: 0.9em;
  margin-top: 20px;
  line-height: 1.4;
}

.footer-copyright {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 0.9em;
}

.Home-link {
  position: absolute;
  top: 0;
  right: 900px;
  margin: 30px 40px 20px 20px;
  color: #5d1a91;
  font-size: calc(1px + 1.8vmin);
  text-decoration: none;
  animation: slideDown 1s ease-in-out;
}

.Home-link:hover {
  color: #b382d5;
  position: absolute;
  top: 0;
  margin: 30px 40px 20px 20px;
}

.Search-link {
  position: absolute;
  top: 0;
  right: 720px;
  margin: 30px 40px 20px 20px;
  color: #5d1a91;
  font-size: calc(1px + 1.8vmin);
  text-decoration: none;
  animation: slideDown 1s ease-in-out;
}

.Search-link:hover {
  color: #b382d5;
  position: absolute;
  top: 0;
  margin: 30px 40px 20px 20px;
}

.Our-listings-link {
  position: absolute;
  top: 0;
  right: 530px;
  margin: 30px 40px 20px 20px;
  color: #5d1a91;
  font-size: calc(1px + 1.8vmin);
  text-decoration: none;
  animation: slideDown 1s ease-in-out;
}

.Our-listings-link:hover {
  color: #b382d5;
  position: absolute;
  top: 0;
  margin: 30px 40px 20px 20px;
}

.Sold-link {
  position: absolute;
  top: 0;
  right: 330px;
  margin: 30px 40px 20px 20px;
  color: #5d1a91;
  font-size: calc(1px + 1.8vmin);
  text-decoration: none;
  animation: slideDown 1s ease-in-out;
}

.Sold-link:hover {
  color: #b382d5;
  position: absolute;
  top: 0;
  margin: 30px 40px 20px 20px;
}

.About-link {
  position: absolute;
  top: 0;
  right: 170px;
  margin: 30px 40px 20px 20px;
  color: #5d1a91;
  font-size: calc(1px + 1.8vmin);
  text-decoration: none;
  animation: slideDown 1s ease-in-out;
}

.About-link:hover {
  color: #b382d5;
  position: absolute;
  top: 0;
  margin: 30px 40px 20px 20px;
}

.Contact-link {
  position: absolute;
  top: 0;
  right: 50px;
  background-color: #5d1a91;
  padding: 10px 15px 10px 15px;
  margin: 18px 10px 20px 10px;
  color: white;
  font-size: calc(1px + 1.8vmin);
  text-decoration: none;
  animation: slideDown 1s ease-in-out;
}

.Contact-link:hover {
  color: white;
  position: absolute;
  top: 0;
  margin: 18px 10px 20px 15px;
  background-color: #8b30c7;
}

.App-logo-link {
  position: absolute;
  top: 0;
  left: 0;
  color: #5d1a91;
  text-decoration: none;
  animation: slideDown 1s ease-in-out;
}

.App-l {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px 40px 20px 20px;
  color: #5d1a91;
  text-decoration: none;
  animation: slideDown 1s ease-in-out;
}

.burger-icon {
  position: absolute;
  right: 0px;
  top: 3px;
  z-index: 10;
  margin: 0px 10px 20px 20px;
  color: #5d1a91;
  font-size: calc(1px + 1.8vmin);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.burger-icon span {
  font-size: 60px;
}

.burger-icon:hover {
  color: #8b30c7;
  transform: scale(1.1);
}

.burger-menu {
  position: fixed;
  top: 0;
  right: -200px;
  width: 200px;
  height: 100%;
  z-index: 20;
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  transition: right 0.3s ease-in-out;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
}

.burger-menu a {
  color: #5d1a91;
  font-size: calc(1px + 1.8vmin);
  text-decoration: none;
  padding: 10px 0;
  display: block;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s ease-in-out;
}

.burger-menu.show {
  right: 0;
}

.burger-menu.show {
  transform: translateX(0%);
}

.burger-menu a:hover {
  color: #8b30c7;
}

.nav-links {
  display: flex;
}

.nav-links.open {
  display: block;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: white;
  width: 200px;
  text-align: right;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.nav-links.open a {
  display: block;
  padding: 10px;
}

.mobile {
  width: 100%;
  height: 700px;
  position: relative;
}

.mobile img {
  position: absolute;
  width: 100%;
}

.mobilePrompt {
  position: absolute;
  z-index: 1;
  top: 350px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.4em;
  font-weight: 500;
  text-align: center;
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  animation: fadeIn 1s ease-in-out;
}

@media (max-width: 1000px) {
  .burger-icon {
    display: block;
    top: 0;
    margin: 0px 10px 20px 20px;
  }

  .burger-icon span {
    font-size: 45px;
  }

  .nav-links {
    display: none;
  }

  .App-logo {
    width: 22mm;
    margin-left: 10px;
    margin-top: 20px;
    animation: slideDown 1s ease-in-out;
    position: fixed;
  }

  .App-header {
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    background-color: hsl(0, 0%, 85%, 0.8);
    position: fixed;
    z-index: 2;
    height: 85px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    color: white;
  }

  .App-header.scrolled .App-logo {
    width: 20mm;
  }

  .footer-right {
    flex: 0 0 100%;
    padding: 0;
  }
  
  .footer-links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .burger-icon {
    position: absolute;
    right: 0px;
    top: 3px;
    z-index: 10;
    margin: 2px 10px 0px 0px;
    color: #5d1a91;
    font-size: calc(1px + 1.8vmin);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .burger-menu {
    position: fixed;
    top: 0;
    right: -200px;
    width: 200px;
    height: 100%;
    z-index: 3;
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    transition: right 0.3s ease-in-out;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .burger-menu a {
    color: #5d1a91;
    font-size: calc(1px + 1.8vmin);
    text-decoration: none;
    padding: 20px 0;
    display: block;
    width: 100%;
    font-size: large;
    text-align: center;
    transition: background-color 0.3s ease-in-out;
  }
}

.House-slider-title {
  font-family: "Quicksand", sans-serif;
  font-size: 45px;
  font-weight: 600;
  margin-left: 100px;
  position: absolute;
  margin-top: 40px;
}

.House-slider-title2 {
  font-family: "Quicksand", sans-serif;
  color: #5d1a91;
  font-size: 45px;
  font-weight: 600;
  margin-top: 110px;
  margin-left: 170px;
  position: absolute;
}

.House-slider-hr {
  width: 22.1%;
  margin-top: 105px;
  margin-left: 0px;
  position: absolute;
  border: 1.5px solid #d0b25e;
}

.HousesSlider {
  margin-top: 230px;
  width: 90%;
  position: relative;
  margin-bottom: 120px;
  margin-left: 5%;
  margin-right: 5%;
}

.House-slider-item {
  padding: 0 10px;
  box-sizing: border-box;
  transition: transform 0.3s;
}

.House-slider-item:hover {
  transform: scaleX(1.03);
}

.House-slider-item:hover .House-slider-item-details {
  opacity: 1;
}

.House-slider-item-details {
  position: absolute;
  width: 300px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 0;
  font-size: 1.2em;
  opacity: 0;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  transition: opacity 0.3s;
}

.House-slider-item-details a {
  color: white;
  text-decoration: none;
  padding-left: 30px;
}

.House-slider-item img {
  width: 300px;
  height: auto;
}

.House-slider-info {
  background-color: #d9d9d9;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: center;
  transition: transform 0.3s;
  height: 270px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto;
  width: 280px;
}

.House-slider-info h3 {
  font-size: 24px;
  margin: 0 0 10px;
}

.House-slider-detail {
  font-size: 0.9em;
  padding-left: 4px;
  color: grey;
}

.House-slider-detail a {
  color: white;
  text-decoration: none;
  padding-left: 30px;
}

.House-slider-details span {
  font-size: 23px;
}

.House-slider-price {
  font-weight: bolder;
  font-family: Cardo, serif;
  font-size: 1.6em;
  color: black;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
}

.House-slider-size {
  font-size: 0.9em;
}

.House-slider-location {
  font-size: 1.1em;
  grid-column: 1 / -1;
  margin: 0;
  padding-left: 4px;
}

.next-arrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  cursor: pointer;
  background-color: hsl(274, 70%, 34%, 0.6);
  width: 20px;
  padding-right: 5px;
  padding-left: 3px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  padding: none;
  color: white;
  border-radius: 30%;
  margin-right: -60px;
  transform: translateY(-50%);
}

.prev-arrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  cursor: pointer;
  background-color: hsl(274, 70%, 34%, 0.6);
  width: 20px;
  padding-right: 3px;
  padding-left: 5px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: white;
  border-radius: 30%;
  margin-left: -60px;
  transform: translateY(-50%);
}

.next-arrow {
  right: 10px;
  padding-right: 5px;
  padding-left: 3px;
}

.next-arrow img {
  width: 20px;
  height: 20px;
  max-height: 50%;
  object-fit: cover;
  margin-bottom: 0px;
}

.prev-arrow {
  left: 10px;
}

.prev-arrow img {
  width: 20px;
  height: 20px;
  max-height: 50%;
  object-fit: cover;
  margin-bottom: 0px;
}

.next-arrow:hover,
.prev-arrow:hover {
  color: white;
  background-color: hsl(274, 70%, 34%, 1);
}

.slick-list {
  overflow: visible !important;
}

@media only screen and (max-width: 1000px) {
  .HousesSlider {
    max-width: 70%;
    margin-left: 15%;
  }

  .House-slider-item {
    padding: 0;
    box-sizing: border-box;
    transition: transform 0.3s;
  }

  .House-slider-hr {
    width: 80%;
    margin-top: 105px;
    margin-left: 0px;
    position: absolute;
    border: 1.5px solid #d0b25e;
  }
}

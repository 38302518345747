.Contact {
  margin: 00px 40px 100px 40px;
}

.contact-header {
  padding-top: 130px;
  margin-left: 60px;
  margin-bottom: 80px;
}

.contact-header-form {
  padding-top: 0px;
}

.contact-header p {
  font-size: 1.2rem;
}
.contact-header-form p {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: black;
}

.contact-header h1 span {
  color: #5d1a91;
}

.contact-header p {
  font-size: 1.2rem;
}

.contact-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.contact-form {
  width: 520px;
  margin-left: 60px;
}

.contact-form-details {
  width: auto;
  display: flex;
  justify-content: center;
}

.contact-form-details input {
  display: block;
  width: auto;
  margin-bottom: 20px;
  padding: 10px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #5d1a91;
  outline: none;
  transition: border-bottom 0.3s;
}
.contact-form-details textarea {
  display: block;
  width: auto;
  margin-bottom: 20px;
  padding: 10px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #5d1a91;
  outline: none;
  transition: border-bottom 0.3s;
}

.contact-top-form-details textarea {
  display: block;
  width: auto;
  margin-bottom: 20px;
  padding: 10px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #5d1a91;
  outline: none;
  transition: border-bottom 0.3s;
}

.contact-form-details input:focus,
.contact-form-details textarea:focus {
  border-bottom: 2px solid #5d1a91;
}

.contact-form-details .name-inputs {
  display: grid;
  gap: 20px;
}

.contact-form-details .email-phone-inputs {
  display: grid;
  gap: 20px;
}
.contact-form-details .name-inputs input {
  grid-row: 1;
}
.contact-form-details .email-and-phone-inputs input {
  grid-row: 1;
}

.contact-form-details textarea {
  resize: none;
  height: 170px;
}

.contact-top-form-details textarea {
  resize: none;
  height: 100px;
}

.top-message-input {
  resize: none;
  height: 50px !important;
}

.contact-form-details button {
  background-color: #5d1a91;
  color: white;
  width: 200px;
  height: 50px;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.top-button {
  background-color: #5d1a91;
  color: white;
  width: 200px;
  height: 40px !important;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.contact-form-details button:hover {
  background-color: #8b30c7;
}

.contact-form input,
.contact-form textarea {
  display: block;
  width: 500px;
  height: 40px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #5d1a91;
  outline: none;
  transition: border-bottom 0.3s;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-bottom: 2px solid #5d1a91;
}

.contact-form .name-inputs {
  display: flex;
  gap: 40px;
}

.contact-form .name-inputs input {
  flex: 1;
}

.contact-form textarea {
  resize: none;
  height: 170px;
}

.contact-form button {
  background-color: #5d1a91;
  color: white;
  width: 200px;
  height: 50px;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #8b30c7;
}

.contact-info {
  margin-top: -30px;
  margin-left: -30px;
  margin-right: 60px;
  padding: 30px;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 85%, 0.8);
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
  background-color: hsl(0, 0%, 85%, 0.7);
}

.contact-info h2 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info-item i {
  font-size: 1.4rem;
  margin-right: 10px;
}

.map-container {
  width: 100%;
  height: 300px;
  margin-top: 30px;
}

.map-container img {
  width: 455px;
  border-radius: 4px;
}

.map-container img:hover {
  transform: scale(1.01);
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .contact-header {
    padding-top: 130px;
    margin-left: 0px;
    margin-bottom: 80px;
  }

  .contact-container {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
    gap: 100px;
  }
  .contact-form {
    width: 100%;
    margin-left: 0px;
  }
  .contact-info {
    margin-top: -30px;
    margin-left: 0;
    margin-right: 20px;
    padding: 30px;
    height: 380px;
    border-radius: 4px;
    border: 1px solid hsl(0, 0%, 85%, 0.8);
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
    background-color: hsl(0, 0%, 85%, 0.7);
  }

  .contact-form input,
  .contact-form textarea {
    display: block;
    width: 280px;
    height: 40px;
    margin-bottom: 20px;
    padding: 10px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #5d1a91;
    outline: none;
    transition: border-bottom 0.3s;
  }
  .contact-form .name-inputs {
    display: block;
    gap: 40px;
  }

  .map-container img {
    width: 230px;
    border-radius: 4px;
  }
}

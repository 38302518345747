@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.SearchBar {
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.SearchBar button:hover {
  background-color: #0056b3;
}

.Filters-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  gap: 15px;
}

.Filter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Filter-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.Filter-label {
  font-size: 14px;
  font-weight: bold;
  margin-top: 60px;
  margin-bottom: 5px;
}

.Price-input-container {
  display: flex;
  align-items: center;
}

.Price-input {
  width: 60px;
  padding: 5px;
  margin: 1px 10px 1px 10px;
  border: 1px solid #5d1a91;
  border-radius: 3px;
  font-size: 14px;
  text-align: center;
}

.Price-input-separator {
  margin: 1px -8px 1px -8px;
}

.Filter-dropdown {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #5d1a91;
  border-radius: 3px;
}

.Filter-button {
  padding: 4px 15px;
  margin-top: 80px;
  height: 30px;
  font-size: 16px;
  background-color: #5d1a91;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s;
  margin-left: 15px;
}

.Filter-button:hover {
  background-color: #8b30c7;
}

.SearchList-container {
  animation: fadeIn 1s ease-in-out;
  position: fixed;
  z-index: 1;
  left: 50%;
  width: 50%;
  transform: translate(-40%, 60%);
  display: flex;
  margin-bottom: 40px;
  margin-top: -10px;
  justify-content: center;
  align-items: center;
}

.Search-container {
  position: absolute;
  animation: fadeIn 1s ease-in-out;
  top: 550px;
}

.Search-input {
  padding: 12px 20px;
  border: 2px solid #5d1a91;
  outline: none;
  justify-content: center;
  width: 600px;
  height: 20px;
  font-size: 18px;
  position: sticky;
}

.Search-input-details {
  width: 140px;
  padding: 5px;
  margin: 2px;
  border: 1px solid #5d1a91;
  border-radius: 3px;
  font-size: 14px;
  text-align: center;
}

.Search-button {
  padding: 12px 20px;
  font-size: 20px;
  background-color: #5d1a91;
  color: white;
  border: none;
  cursor: pointer;
  height: 48px;
  transition: background-color 0.3s;
}

.Search-button:hover {
  background-color: #8b30c7;
}

.Error-message {
  color: red;
  font-size: 10px;
  margin-top: 2px;
  animation: slideDown 0.5s ease-in-out;
}

.Input-error {
  border: 1px solid red;
  animation: fadeIn 0.5s ease-in-out;
}

.SearchList-container .Larger-search-list {
  position: fixed;
  top: -85px;
  width: 100%;
  z-index: 1;
}

@media (max-width: 1000px) {
  .Filters-wrapper {
    z-index: 1;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
  }

  .Filter-container {
    position: fixed;  
    top: 0;  
    left: 0; 
    height: 100vh; 
    width: 100vw;  
    background-color: rgba(255, 255, 255);  
    z-index: 9999;  
    overflow-y: auto;  
    flex-direction: column;  
    align-items: center;  
    justify-content: center;  
  }

  .Filter-container-mobile {
    margin-top: -67px;
  }

  .Filter-item {
    margin-bottom: 15px;
  }

  .Filter-label {
    margin-top: 0;
    margin-bottom: 5px;
  }

  .SearchList-container {
    width: 100%;
    transform: translateY(20px);
    padding: 0;
    position: fixed;
    left: 40%;
    display: flex;
    margin-top: 0px;
    background-color: rgba(255, 255, 255, 0.9);
    transform: translate(-40%, 60%);
    margin: 0;
    z-index: 10;
  }

  .Search-container {
    position: absolute;
    animation: fadeIn 1s ease-in-out;
    top: 190px;
  }

  .SearchBar {
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .Search-input {
    padding: 8px 20px;
    border: 2px solid #5d1a91;
    outline: none;
    justify-content: center;
    width: 200px;
    height: 20px;
    font-size: 18px;
    position: sticky;
    border-radius: 0;
  }

  .Search-input-details {
    width: 140px;
    padding: 5px;
    margin: 2px;
    border: 1px solid #5d1a91;
    border-radius: 3px;
    font-size: 14px;
    text-align: center;
    z-index: 2;
  }

  .Search-button {
    padding: 8px 20px;
    font-size: 20px;
    background-color: #5d1a91;
    color: white;
    border: none;
    cursor: pointer;
    height: 40px;
    transition: background-color 0.3s;
  }

  .Filter-button {
    margin-top: 20px;
    margin-left: 0;
  }

  .SearchList-container .Larger-search {
    position: fixed;
    top: 65px;
    width: 100%;
    z-index: 0;
  }

  .SearchList-container .Larger-search-list {
    position: fixed;
    top: 65px;
    width: 100%;
    z-index: 1;
  }

  .Filter-item-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Filter-item-main input {
    width: 170px;
  }
}

.FeaturedListings {
  display: contents;
  flex-direction: column;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
}

.FeaturedListings-grid {
  display: grid;
  grid-template-columns: 40% 30% 30%;
  grid-template-rows: 280px 1fr;
  gap: 0;
  align-items: start;
  margin-left: 5px;
  margin-right: 12px;
}

.FeaturedListings-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(100%);
  transition: transform 0.3s ease, filter 0.3s ease;
  width: 100%;
  height: 100%;
  position: relative;
}

.FeaturedListings-image:hover {
  filter: grayscale(0%);
  transform: scale(1.1);
}

.FeaturedListings-image:nth-child(1) {
  grid-row: 1;
  grid-column: 1;
  height: calc(100% - 10px);
  width: calc(100% + 30px);
  align-self: start;
}

.FeaturedListings-image:nth-child(2) {
  height: calc(100% + 20px);
  width: calc(100% - 34px);
  align-self: start;
  margin-left: 34px;
  margin-bottom: 5px;
}
.FeaturedListings-image:nth-child(3) {
  height: calc(100% + 20px);
  align-self: start;
  padding-left: 4px;
  margin-left: 4px;
  margin-bottom: 5px;
}

.FeaturedListings-image:nth-child(4) {
  height: calc(100% - 80px);
  width: calc(100% - 70px);
  margin-top: -6px;
}

.FeaturedListings-image:nth-child(5) {
  grid-row: 2;
  grid-column: 2;
  height: calc(100% - 50px);
  margin-left: -66px;
  margin-top: 24px;
  align-self: start;
}

.FeaturedListings-title {
  grid-row: 2;
  grid-column: 3;
  transition: transform 0.3s ease, filter 0.3s ease;
  object-fit: cover;
  display: block;
  flex-direction: column;
  height: calc(60%);
  width: calc(100% - 10px);
  align-items: flex-end;
  justify-content: flex-start;
  border: 20px solid rgb(128, 128, 128, 0.5);
  grid-column: 3;
  grid-row: 2;
  padding: 20px;
  margin-left: -62px;
  margin-top: 24px;
  padding-top: 30px;
}

.FeaturedListings-title:hover {
  filter: grayscale(0%);
  transform: scale(1.1);
}

.FeaturedListings-title h1 {
  margin-bottom: 10px;
}

.FeaturedListings-title h2 {
  justify-content: center;
  align-items: center;
  font-size: 48px;
  font-weight: bold;
  margin: 10px 0;
}

.FeaturedListings-title-featured {
  color: black;
  display: inline;
  font-size: 70px;
  font-weight: 400;
}

.FeaturedListings-title-listings {
  color: #5d1a91;
  font-size: 40px;
  font-weight: 400;
  margin-left: 5px;
  margin-top: -13px;
}

.FeaturedListings-button {
  background-color: white;
  color: black;
  position: relative;
  bottom: 0px;
  left: 5px;
  border: 1px solid black;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 10px;
}
.LoadDiv2 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  z-index: 1000;
}
.FeaturedListings-button:hover {
  background-color: #5d1a91;
  color: white;
}

.FeaturedListings-city {
  font-size: 25px;
  color: white;
  margin-bottom: 0px;
  margin-left: 50px;
  margin-top: 30px;
  display: flex;
  font-weight: 900;
  font-family: Cardo, serif;
}

.FeaturedListings-details-m {
  color: white;
  font-weight: 500;
  margin-left: 50px;
  margin-right: 180px;
  margin-bottom: 10px;
  display: flex;
  opacity: 0;
}

.FeaturedListings-details-s {
  color: white;
  font-weight: 500;
  margin-left: 50px;
  margin-right: 100px;
  margin-bottom: 10px;
  display: flex;
  opacity: 0;
}

.FeaturedListings-details-ea {
  color: white;
  font-weight: 500;
  margin-left: 50px;
  margin-right: 120px;
  margin-bottom: 10px;
  display: flex;
  opacity: 0;
}

.FeaturedListings-details-b {
  color: white;
  font-weight: 500;
  margin-left: 50px;
  margin-right: 140px;
  margin-bottom: 10px;
  display: flex;
  opacity: 0;
}

.FeaturedListings-details-ed {
  color: white;
  font-weight: 500;
  margin-left: 50px;
  margin-right: 120px;
  margin-bottom: 10px;
  display: flex;
  opacity: 0;
}

.FeaturedListings-details-button {
  display: flex;
  width: 70px;
  background-color: #5d1a91;
  color: white;
  border: none;
  padding: 5px 8px 5px 8px;
  margin-left: 50px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.FeaturedListings-image:hover .FeaturedListings-details-button {
  opacity: 1;
}

.FeaturedListings-image:hover .FeaturedListings-details-m {
  opacity: 1;
  transition: opacity 1.4s ease;
}

.FeaturedListings-image:hover .FeaturedListings-details-s {
  opacity: 1;
  transition: opacity 1.4s ease;
}

.FeaturedListings-image:hover .FeaturedListings-details-ea {
  opacity: 1;
  transition: opacity 1.4s ease;
}

.FeaturedListings-image:hover .FeaturedListings-details-b {
  opacity: 1;
  transition: opacity 1.4s ease;
}

.FeaturedListings-image:hover .FeaturedListings-details-ed {
  opacity: 1;
  transition: opacity 1.4s ease;
}

.FeaturedListings-details-button:hover {
  background-color: #8b30c7;
  color: white;
  cursor: pointer;
}

.House-slider-info {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.House-slider-item img {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.House-slider-item-details {
  border-radius: 4px;
}

@media (max-width: 1000px) {
  .FeaturedListings-grid {
    display: flow-root;
    margin: 0;
    margin-top: 10px;
  }

  .FeaturedListings-title-featured {
    color: black;
    display: inline;
    font-size: 50px;
    font-weight: 400;
  }
  
  .FeaturedListings-title-listings {
    color: #5d1a91;
    font-size: 40px;
    font-weight: 400;
    margin-left: 5px;
    margin-top: -13px;
  }

  .FeaturedListings-image,
  .FeaturedListings-image:nth-child(1),
  .FeaturedListings-image:nth-child(2),
  .FeaturedListings-image:nth-child(3),
  .FeaturedListings-image:nth-child(4),
  .FeaturedListings-image:nth-child(5) {
    width: 90%;
    height: 200px;
    display: block;
    margin: 0 auto 20px auto;
    filter: none;
  }

  .FeaturedListings-details-m,
  .FeaturedListings-details-s,
  .FeaturedListings-details-ea,
  .FeaturedListings-details-b,
  .FeaturedListings-details-ed,
  .FeaturedListings-details-button {
    font-size: 12px;
    margin-left: 20px;
  }

  .FeaturedListings-city {
    font-size: 20px;
    margin-left: 20px;
    padding-top: 20px;
  }

  .FeaturedListings-title {
    grid-row: 2;
    grid-column: 3;
    transition: transform 0.3s ease, filter 0.3s ease;
    object-fit: cover;
    display: block;
    flex-direction: column;
    height: calc(60%);
    width: calc(70% - 1px);
    align-items: flex-end;
    justify-content: flex-start;
    border: 20px solid rgb(128, 128, 128, 0.5);
    grid-column: 3;
    grid-row: 2;
    padding: 20px;
    margin-left: 20px;
    margin-top: 24px;
    padding-top: 30px;
  }
  

}

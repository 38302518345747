@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Search-and-results {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Larger-search {
  position: inherit;
  z-index: 4;
}

.Search-container {
  position: absolute;
  z-index: 1;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Slogan {
  position: absolute;
  z-index: 1;
  top: 470px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
  font-weight: 500;
  text-align: center;
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  animation: fadeIn 1s ease-in-out;
}

.SloganMobile {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 110px;
  padding-bottom: 50px;
  font-size: 37px;
  font-weight: 500;
  text-align: center;
  color: rgb(0, 0, 0);
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  animation: fadeIn 1s ease-in-out;
}

.House-container {
  position: relative;
  max-width: 100%; 
  max-height: 100%; 
}

.slideshow img {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: slideShow 20s infinite; 
}

.slideshow img:nth-child(2) {
  animation-delay: 5s; 
  animation-fill-mode: forwards;
}

.slideshow img:nth-child(3) {
  animation-delay: 10s; 
  animation-fill-mode: forwards;
}

.slideshow img:nth-child(4) {
  animation-delay: 15s; 
  animation-fill-mode: forwards;
}

/* Keyframes for zoom effect */
/* Keyframes for zoom effect */
@keyframes slideShow {
  0% {
    opacity: 0;
    transform: scale(1);
    transition-timing-function: ease;
  }
  10% {
    opacity: 1;
    transition-timing-function: ease;
  }
  25% {
    opacity: 1;
    transform: scale(1.1);
    transition-timing-function: ease;
  }
  33% {
    opacity: 0;
    transform: scale(1.1);
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    transition-timing-function: ease;
  }
}

.slideshow {
  width: 100%;
  height: 700px;
  position: relative;
}

@media only screen and (max-width: 1000px) {
  .House-container {
    display: none;
  }

  .Larger-search {
    position: inherit;
    z-index: 2;
  }
}

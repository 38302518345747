
.mortgage-container {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(150, 150, 150, 0.1);
  
}

.mortgage-wrapper {
  height: auto;
  width: auto;
  border-radius: 20px;
  padding: 20px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  
}

.mortgage-header {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.mortgage-header button {
  height: max-content;
  width: max-content;
  padding: 10px;
  border: none;
  background-color: transparent;
  font-size: 35px;
  color: #9088d2;
}

.mortgage-header button:hover {
  cursor: pointer;
}

.mortgage-view {
  width: 40%;
  margin-top: -10px;
}
.mortgage-breakup {
  width: 27%;
  margin-top: -60px;
  margin-left: 10%;
}

.mortgage-details input {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}

.mortgage-detail {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.mortgage-footer {
  display: flex;
  justify-content: space-between;
}

/*----- Styling the sliders ----*/
.mortgage-details input[type="range"] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}

.mortgage-details input[type="range"]:focus {
  outline: none;
}

.mortgage-details input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #9088d2;
  border-radius: 10px;
}

.mortgage-details input[type="range"]::-webkit-slider-thumb {
  box-shadow: 2px 2px 5px #b3b3b3, -1px -1px 3px grey;
  border: 5px solid #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #9088d2;
  cursor: pointer;
  margin-top: -6px;
  -webkit-appearance: none;
}

.mortgage-details input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #b3b3b3;
}

.mortgage-details input[type="range"]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #9088d2;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
  border-radius: 10px;
}

.mortgage-details input[type="range"]::-moz-range-thumb {
  box-shadow: 2px 2px 5px #b3b3b3, -1px -1px 3px grey;
  border: 5px solid #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #9088d2;
  cursor: pointer;
  margin-top: -6px;
}

.mortgage-details input[type="range"]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: #9088d2;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
  border-radius: 10px;
}

.mortgage-details input[type="range"]::-ms-fill-lower {
  background: #9088d2;
  border-radius: 5px;
}

.mortgage-details input[type="range"]::-ms-fill-upper {
  background: #9088d2;
  border-radius: 5px;
}

.mortgage-details input[type="range"]::-ms-thumb {
  box-shadow: 2px 2px 5px #b3b3b3, -1px -1px 3px grey;
  border: 5px solid #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #9088d2;
  cursor: pointer;
  margin-top: -6px;
}

.mortgage-details input[type="range"]:focus::-ms-fill-lower {
  background: #9088d2;
}

.mortgage-details input[type="range"]:focus::-ms-fill-upper {
  background: #9088d2;
}

.loan-amount-input {
  border: 1px solid #9088D2;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  font-size: 15px;
}

/*-------------------------------------*/
.mortgage-price {
  color: #130f31;
  font-size: 25px;
}

.mortgage-loan-details {
  width: 90%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.mortgage-price-container {
  color: #9088d2;
  margin-top: 50px;
}

.mortgage-price-container::before {
  content: "Monthly Payable:";
  font-size: 12px;
  display: block;
}

.sub-container {
    display: flex;
    width: 100%;
    gap: 50px;
    height: max-content;
    margin-bottom: 2rem;
  }



@media (max-width: 1000px) {
  .sub-container {
    display: flex;
    width: 100%;
    gap: 10px;
    height: max-content;
    margin-bottom: 2rem;
  }
  .mortgage-view {
    width: 40%;
    margin-top: -5px;
  }
  .mortgage-breakup {
    width: 56%;
    margin-top: 0px;
    margin-left: 10px;
  }
  .mortgage-loan-details {
    width: 80%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .mortgage-wrapper {
    height: 1000px;
    width: auto;
    border-radius: 20px;
    padding: 20px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    
  }
}
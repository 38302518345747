/* HouseSearchResult.css */

.House-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  padding-top: 130px;
}

.House-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 260px;
  background-color: #d9d9d9;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
  transition: transform 0.3s;
}

.House-item:hover {
  transform: scale(1.05);
}

.House-item-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.House-item-info {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto;
  gap: 8px;
  padding: 16px;
}

.House-item-data {
  color: black !important;
  grid-column: 2;
}

.House-item-detail {
  font-size: 0.8em;
  color: grey;
  padding-left: 4px;
}

.House-item-details {
  position: absolute;
  width: 260px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 0;
  font-size: 1.2em;
  opacity: 0;
  transition: opacity 0.3s;
}

.House-item:hover .House-item-details {
  opacity: 1;
}

.House-item-details a {
  color: white;
  text-decoration: none;
  padding-left: 30px;
}

.House-item-city {
  font-size: 0.9em;
  grid-column: 1 / -1;
  margin: 0;
  padding-left: 4px;
}

.House-item-price {
  font-weight: bolder;
  font-family: Cardo, serif;
  font-size: 1.6em;
  color: black;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 4px;
}

.House-item-divider {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 0px;
  margin-bottom: 26px;
}

.LoadDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  z-index: 1000;
}

.No-results-message {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 130px);
  font-size: 1.2em;
  font-weight: 600;
  color: black;
}

.No-results-message-header {
  border: 1px solid rgb(166, 164, 164, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  padding: 30px;
  width: 50%;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.pagination-container button {
  background-color: white;
  border: none;
  color: #5d1a91;
  border: 1px solid #5d1a91;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  padding: 5px 20px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.pagination-container button:hover {
  background-color: #d6c0e7;
}

.pagination-container button:disabled {
  background-color: white;
  border: 1px solid rgb(152, 151, 151);
  color: rgb(152, 151, 151);
  cursor: not-allowed;
}

.pagination-container span {
  margin: 0 10px;
  font-size: 16px;
}

@media (max-width: 1000px) {
  .House-list {
    padding-top: 100px;
  }

  .House-item {
    width: 250px;
  }

  .House-item-image {
    height: 150px;
  }

  .House-item-info {
    padding: 10px;
  }

  .House-item-detail {
    font-size: 0.7em;
  }

  .House-item-details {
    width: 250px;
    font-size: 1em;
  }

  .House-item-city {
    font-size: 0.8em;
  }

  .House-item-price {
    font-size: 1.2em;
  }

  .No-results-message-header {
    width: 80%;
  }
}
